<template>
    <div>
        <el-page-header @back="$router.go(-1)" :content="$t('system.routes')"/>
        <el-tree class="tree" :data="routes" :props="treeProps" default-expand-all :expand-on-click-node="false" @node-click="handleNodeClick">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span v-if="data.redirect" type="text" size="mini" @click="() => goto(data)">
                    redirect: {{data.redirect}}
                </span>
<!--                <span v-if="data.component" type="text" size="mini" @click="() => goto(data)">-->
<!--                    component: {{data.component.name}}-->
<!--                </span>-->
              </span>
        </el-tree>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                treeProps: {
                    label: 'path',
                    children: 'children'
                },
                routes: this.$router.options.routes
            }
        },
        methods: {
            handleNodeClick(node) {
                this.$router.replace({
                    path: node.path
                })
            }
        },
        mounted() {
            console.log(this.$router.options.routes)
        }
    }
</script>

<style scoped>
    .tree {
        margin-top: 30px;
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
